<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config.json";
import ToolStatusDisplay from "@/components/tool-status.vue";

import "prismjs";
import "prismjs/themes/prism.css";
import Pagination from "@/views/ui/pagination.vue";
import {emojiCategoryService} from "@/helpers/blogservice/emoji.category.service";

export default {
  page: {
    title: "Basic",
    meta: [{ name: "description", content: appConfig.description }],
  },
  mounted() {
    this.gets();
  },
  data() {
    return {
      title: "Emoji Category",
      items: [
        {
          text: "Emoji",
          href: "/",
        },
        {
          text: "Emoji",
          active: true,
        },
      ],
      siteDomain: process.env.VUE_APP_EMOJI_URL,
      models: [],
      pagination: {
        pageIndex: 0,
        pageSize: 30,
        totalRow : 0
      },
      paginationKey: 0,
      filter: {
        keyword: '',
        status: 0
      }
    };
  },
  methods: {
    async gets() {
      const request = {
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
        keyword: this.filter.keyword,
        status: this.filter.status
      };
      const response = await emojiCategoryService.gets(request);
      if (response?.data?.status) {
        this.models = response.data?.data?.models ?? [];
        this.pagination.totalRow = response.data?.data?.total_row ?? 0;
        this.pagination.pageIndex = response.data?.data?.page_index ?? 0;
        this.pagination.pageSize = response.data?.data?.page_size ?? 30;
        this.paginationKey += 1;
      }
    },
    async onSelectPage(page) {
      this.pagination.pageIndex = page;
      await this.gets();
    },
    async changeStatus(id, status) {
      await emojiCategoryService.statusChange({
        id, status
      });
      await this.gets();
    },
  },
  components: {
    Layout,
    PageHeader,
    Pagination,
    ToolStatusDisplay
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Emoji Category</h4>
            <div class="flex-shrink-0 hstack gap-2">
              <button type="button" class="btn btn-info mr-5" @click="gets">
                <i class="las la-search"></i> Search
              </button>
              <router-link to="/emoji/category/add" class="btn btn-primary">
                Add
              </router-link>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <div class="live-preview">

              <div class="row gy-4">
                <div class="col-xxl-3 col-md-6">
                  <div>
                    <label for="filterKeyword" class="form-label">Keyword</label>
                    <input
                        v-model="filter.keyword"
                        type="text"
                        class="form-control"
                        id="filterKeyword">
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6">
                  <div>
                    <label for="filterKeyword" class="form-label">Status</label>
                    <select name="filterStatus" id="filterStatus" class="form-control" v-model="filter.status">
                      <option value="0">All</option>
                      <option value="1">Draft</option>
                      <option value="2">Published</option>
                      <option value="4">UnPublish</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                  ref="toolPagination"
                  :key="paginationKey"
                  :onSelectPageFunc="onSelectPage"
                  :page-index="pagination.pageIndex"
                  :page-size="pagination.pageSize"
                  :total-row="pagination.totalRow" />

              <div class="table-responsive mt-5">
                <table class="table align-middle table-nowrap mb-0 table-hover">
                  <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 42px">
                      <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="responsivetableCheck"
                        />
                        <label
                            class="form-check-label"
                            for="responsivetableCheck"
                        ></label>
                      </div>
                    </th>
                    <th scope="col">Title</th>
                    <th scope="col">Seo</th>
                    <th scope="col">Status</th>
                    <th scope="col">Publish</th>
                    <th scope="col">#</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr v-for="item in models" :key="item.id">
                    <th scope="row">
                      <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="responsivetableCheck01"
                        />
                        <label
                            class="form-check-label"
                            for="responsivetableCheck01"
                        ></label>
                      </div>
                    </th>
                    <td>
                      <router-link
                          :to="'/emoji/category/change/' + item.id"
                      >{{ item.title }}
                      </router-link
                      >
                    </td>
                    <td>
                      {{ item.seo?.title??'' }}
                      <br>
                      {{ item.seo?.keyword??'' }}
                    </td>
                    <td>
                      <ToolStatusDisplay :status="item.status" />
                    </td>
                    <td>
                      <div class="hstack gap-3 flex-wrap">
                        <button
                            @click="changeStatus(item.id, 2)"
                            v-if="item.status === 1 || item.status === 4"
                            type="button"
                            class="btn btn-sm btn-success">Publish</button>
                        <button
                            @click="changeStatus(item.id, 4)"
                            v-if="item.status === 2"
                            type="button"
                            class="btn btn-sm btn-light btn-success">UnPublish</button>
                      </div>
                    </td>
                    <td>
                      <a target="_blank" :href="siteDomain + item.slug"><i class="las la-external-link-square-alt"></i></a>
                    </td>
                  </tr>

                  </tbody>
                </table>
                <!-- end table -->
              </div>
              <!-- end table responsive -->

              <Pagination
                  ref="toolPagination"
                  :key="paginationKey"
                  :onSelectPageFunc="onSelectPage"
                  :page-index="pagination.pageIndex"
                  :page-size="pagination.pageSize"
                  :total-row="pagination.totalRow" />

            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!--end row-->
  </Layout>
</template>
